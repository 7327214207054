/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button, Stack } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import TripPlannerTripsCard from './TripPlannerTripsCard';
import { Driver, Group, Tag } from '../../../redux/models/settings.models';
import { createDropdownOptions } from '../../../utils/core.utils';
import { BoardSettings } from '../../../redux/models/board.models';
import TripPlannerDriverCard from './TripPlannerDriverCard';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { EntityContainer } from '../../../redux/models/core.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { TripPlan } from '../../../redux/slices/planner/planner.models';
import {
 resetFilters,
 selectDriverFilters,
 updateDraftDriver,
} from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import ResetPlanButton from './components/ResetPlanButton';
import { findDriverAvailability, sortPlannedDrivers } from '../../../redux/slices/planner/planner.utils';
import DropWrapper from './components/DropWrapper';
import { ItemModel } from './components/ItemModel';

const tripsFilter = (driverId: string, plan: TripPlan) => {
  const plans = plan.data.plans || {};
  const assigned = plans[driverId] && plans[driverId].length > 0;
  return assigned;
};

const avFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

const tagFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

interface Props {
  availability: DriverAvailability[];
  boardSettings: BoardSettings;
  drivers: Driver[];
  groups: Group[];
  plan: TripPlan;
  tags: Tag[];
  tripContainer: EntityContainer<TripBoardModel>;
}

export default function TripPlannerContainer({
  availability, groups, drivers, boardSettings, plan, tripContainer, tags
}: Props) {
  const dispatch = useAppDispatch();
  const filters = useSelector(selectTripsFilterParams);
  const selectedDriverFilter = useSelector(selectDriverFilters);
  const {
    available,
    numTrips,
    tagFilters,
    outsideCartage
  } = selectedDriverFilter;
  const isTagFilters = Object.values(tagFilters).find((filter) => filter);
  const driverOptions = createDropdownOptions(drivers, 'samsara_name');
  
  const unSortedData = drivers.filter((option) => {
    const driverId = option.entity_id;
    const driverFilters: boolean[] = [];
    const isSelected = selectedDriverFilter.selected[driverId] || false;
    const isHidden = selectedDriverFilter.hidden[driverId] || false;
    if (isHidden) return false;
    if (numTrips) driverFilters.push(tripsFilter(driverId, plan));
    if (available) driverFilters.push(avFilter(driverId, filters.dateFrom, availability));
    if (isTagFilters) driverFilters.push(tagFilter(driverId, filters.dateFrom, availability));
    if (isTagFilters) driverFilters.push(tagFilter(driverId, filters.dateFrom, availability));
    if (outsideCartage) driverFilters.push(option.data.outside_cartage);
    if (isSelected) driverFilters.push(isSelected);
    return driverFilters.find((flt) => flt);
  });
  const driverData = sortPlannedDrivers(filters.dateFrom, unSortedData, availability);

  const handleResetFilters = () => {
    dispatch(resetFilters());
  };
  const onDrop = (item: ItemModel, _: any, driverId: string) => {
    dispatch(updateDraftDriver(driverId, item.trip));
  };
  return (
    <>
      <Stack direction="horizontal" className="mb-3 justify-content-between" gap={3}>
        <Stack direction="horizontal" gap={3}>
          <Button type="button" variant="outline-secondary" onClick={handleResetFilters}>Reset Filters</Button>
          <ResetPlanButton plan={plan} trips={tripContainer} />
        </Stack>
      </Stack>
      <Row>
        <DndProvider backend={HTML5Backend}>
          <Col md={6}>
            <TripPlannerDriverCard
              data={driverData}
              availability={availability}
              drivers={drivers}
              driverOptions={driverOptions}
              boardSettings={boardSettings}
              plan={plan}
              tags={tags}
              handleDrop={onDrop}
            />
          </Col>
          <Col md={6}>
            <DropWrapper
              driverId=""
              onDrop={onDrop}
            >
              <TripPlannerTripsCard
                groups={groups}
                drivers={driverOptions}
                plan={plan}
              />
            </DropWrapper>
        </Col>
        </DndProvider>
      </Row>
    </>
  );
}
