import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { TRIP_TABLE_HEADERS } from './constants/tripTable.constants';

import Loader from '../../../components/Loader';
import TripsTableRow from './TripsTableRow';
import { selectAreTripsLoading, selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { TripTableColumns } from '../../../redux/models/board.models';
import { DateService } from '../../../utils/dateService';
import { EntityContainer } from '../../../redux/models/core.models';
import { selectDriversObject } from '../../../redux/slices/settings';

const NO_TRIPS_MESSAGE = 'No trips for this group';

interface Props {
  trips: TripBoardModel[];
  title: string;
  headers: TripTableColumns[];
  driverPlan: EntityContainer<string>;
}

export default function TripsTable({
  trips,
  title,
  headers = TRIP_TABLE_HEADERS,
  driverPlan,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const drivers = useSelector(selectDriversObject);
  const isSameDay = DateService.isSame(dateFrom, dateTo);
  const openTripPage = (id: string) => navigate(`/${orgCode}/trips/${id}`);
  const renderNoTripsMessage = () => (areTripsLoading ? <Loader /> : <div>{NO_TRIPS_MESSAGE}</div>);
  return (
    trips.length ? (
      <Table
        striped
        bordered
        hover
        className="trips-table"
        data-cy={`${title}_trips-table`}
      >
        <thead>
          <tr>
            {headers.map((header) => <th key={header.key}>{header.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {trips.map((trip, index) => {
            const driverId = driverPlan[trip.id] || '';
            const driverName = drivers[driverId]?.data.samsara_username || drivers[driverId]?.data.samsara_name || '';
            return (
              <tr
                key={trip.id}
                id={`boards-tripsTable__tableRow-${index}-${trip.id}`}
                className="trips-table-row"
                data-cy={`${title}_trips-table-row`}
                onClick={() => openTripPage(trip.id)}
              >
                {headers.map((header) => (
                  <td
                    key={`${trip.id}-${header.key}`}
                    data-cy={`boards-tripsTable__trip-${index}-${header.key}`}
                    id={`boards-tripsTable__trip-${index}-${header.key}`}
                  >
                    <TripsTableRow
                      trip={trip}
                      prop={header.key}
                      driverName={driverName}
                      boardDate={dateFrom}
                      isDateRange={!isSameDay}
                    />
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
    ) : renderNoTripsMessage()
  );
}
