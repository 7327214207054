/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Stack,
  Dropdown,
  ButtonGroup,
  Button,
  DropdownButton,
  Badge,
} from 'react-bootstrap';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setFilterParams, selectTripsFilterParams, getTrips } from '../../redux/slices/trips/trips';
import { createBoardShipment, createBoardTrip } from '../../redux/slices/shipments';
import { boardShipment, boardTrip, FILTER_KEYS } from './constants/boards.constants';
import { SearchDateRange } from '../../components/modals/SearchFilter/searchFilter.models';
import { Shipment } from '../../redux/models/shipment.models';
import {
  getFilterValueFromSessionStorage,
  formatOrgName,
} from '../../utils/core.utils';

import { Board, BoardSettings } from '../../redux/models/board.models';
import {
  applyDraft, dispatchDraft, optimisePlan, removeCurrentPlan, saveDraft, selectCurrentPlan, setCurrentPlan
} from '../../redux/slices/planner/planner';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import { Trip } from '../../redux/models/trip.models';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';
import { DateService } from '../../utils/dateService';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import ButtonSpinner from '../../components/shared/ButtonSpinner';
import DraftNotesModal from './TripPlanner/DraftNotesModal';
import Analytics from '../../utils/analytics';
import { TripPlan } from '../../redux/slices/planner/planner.models';
import { AppState } from '../../redux/models/state.models';
import { selectDriversByTags } from '../../redux/slices/settings';
import SavePlanButton from './TripPlanner/components/SavePlanButton';
import ConfirmModal from '../../components/modals/ConfirmModal';
import ApiResponse from '../../services/base/ApiResponse';
import useAppSettings from '../../hooks/useAppSettings';
import { AsyncDispatchNotificationResponse } from '../../redux/slices/dispatch/dispatch.models';
import { setMultiTripDispatch } from '../../redux/slices/notifications/notifications';

const BUTTON_TEXT = 'Add';

interface Props {
  board: Board;
  boardSettings: BoardSettings;
}

function Header({
  board,
  boardSettings
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, orgCode } = useParams();
  const { timezone } = useAppSettings();
  const { optimise_planner: optimisePlanner, planner_dispatch: dispatchPlanner } = useFeatureFlags();
  const [isOptimising, setIsOptimising] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isConfirmAssignOpen, setIsConfirmAssignOpen] = useState(false);
  const [isConfirmAssignDispatchOpen, setIsConfirmAssignDispatchOpen] = useState(false);
  const [isAssigningDrivers, setIsAssigningDrivers] = useState(false);
  const filterParams: SearchDateRange = useSelector(selectTripsFilterParams);
  const tags = board.data.driver_tags || [];
  const drivers = useSelector((state: AppState) => selectDriversByTags(state, tags));
  const plan = useSelector(selectCurrentPlan);
  const isNotes = plan?.data.notes !== '' && plan?.data.notes !== undefined && plan?.data.notes !== null;
  
  const boardId = board?.entity_id;
  const noteTemplates = board?.data.note_templates || [];
  const noteTemplate = noteTemplates && noteTemplates.length > 0 ? noteTemplates[0] : null;
  const noteTemplateId = noteTemplate?.entity_id;
  const defaultNotes = noteTemplate?.data.notes || [];
  const isOptmiseEnabled = optimisePlanner?.is_enabled || false;
  const isDispatchEnabled = dispatchPlanner?.is_enabled || false;
  const offset = DateService.getTimezoneOffset(timezone);

  const openShipmentPage = (shipmentId: string) => {
    dispatch(resetShipments());
    navigate(`/${orgCode}/shipments/${shipmentId}`);
  }
  const openTripPage = (tripId: string) => {
    dispatch(resetShipments());
    navigate(`/${orgCode}/trips/${tripId}`);
  }

  const handleAddTrip = async () => {
    try {
      const defaultData: any = boardTrip(filterParams.dateFrom, boardId, '', '', [], defaultNotes);
      const trip: Trip = await createBoardTrip(defaultData, orgCode);
      if (trip.entity_id) {
        openTripPage(trip.entity_id);
      }
    } catch (e: any) {
      const errorMessage = `Couldn't add trip. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  };

  const handleAddTripShipment = async () => {
    try {
      const defaultData: any = boardShipment(filterParams.dateFrom, boardId, '', '', [], noteTemplateId, defaultNotes);
      const shipment: Shipment = await createBoardShipment(defaultData, orgCode);
      if (shipment.entity_id) {
        openShipmentPage(shipment.entity_id);
      }
    } catch (e: any) {
      const errorMessage = `Couldn't add trip. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  };

  const handleOpenNotes = () => {
    setIsNotesOpen(true);
  };

  const handleNetworkOptimise = async (greedy: boolean) => {
    try {
      setIsOptimising(true);
      const driverIds = drivers.map((dr) => dr.entity_id);
      const response = await optimisePlan(plan, driverIds, greedy);
      if (response.status === 200) {
        const optimised: TripPlan = response.data.data;
        dispatch(setCurrentPlan(optimised));
      }
    } catch (error) {
      Analytics.capture(error);
      toast("Couldn't optimise trips", { type: 'error' });
    } finally {
      setIsOptimising(false);
      Analytics.optimiseDispatch(orgCode, greedy);
    }
  };

  const handleApply = async () => {
    try {
      setIsAssigningDrivers(true);
      const saveResponse = await saveDraft(plan);
      const saveEntity: any = ApiResponse.parseItem(saveResponse);
      await applyDraft(saveEntity);
      await dispatch(removeCurrentPlan(plan.entity_id));
      await dispatch(getTrips(id || '', filterParams, boardSettings));
      navigate(`/${orgCode}/boards/${id}`);
    } catch (error) {
      if (error instanceof Error) {
        toast(`Error assigning drivers. ${error.message}`, { type: 'error' });
      }
    } finally {
      setIsAssigningDrivers(false);
      Analytics.applyDraft(orgCode);
    }
  };
  const handleDispatch = async () => {
    try {
      setIsAssigningDrivers(true);
      const saveResponse = await saveDraft(plan);
      const saveEntity: any = ApiResponse.parseItem(saveResponse);
      const planDispatch = {
        user_timezone: offset,
        company_name: formatOrgName(orgCode),
      }
      const response = await dispatchDraft(saveEntity, planDispatch);
      if (response.status === 200) {
        const queuedDispatches: AsyncDispatchNotificationResponse = {
          ...response.data.data?.dispatches,
          message: 'Finished processing dispatches for trip plan'
        };
        console.log('handleDispatch: ');
        console.log('dispatchDraft: ');
        console.log(queuedDispatches);
        await dispatch(setMultiTripDispatch(queuedDispatches));
        await dispatch(removeCurrentPlan(plan.entity_id));
        await dispatch(getTrips(id || '', filterParams, boardSettings));
      }
    } catch (error) {
      if (error instanceof Error) {
        toast(`Error assigning drivers. ${error.message}`, { type: 'error' });
      }
    } finally {
      setIsAssigningDrivers(false);
      Analytics.dispatchDraft(orgCode);
    }
  };

  const handleConfirmApply = () => {
    setIsConfirmAssignOpen(true);
  }
  const handleConfirmApplyDispatch = () => {
    setIsConfirmAssignDispatchOpen(true);
  }
  useEffect(() => {
    const dateFromParams = getFilterValueFromSessionStorage(FILTER_KEYS.DATE_FROM);
    const dateToParams = getFilterValueFromSessionStorage(FILTER_KEYS.DATE_TO);
    const dateTypeParams = getFilterValueFromSessionStorage(FILTER_KEYS.DATE_TYPE);
    if (dateFromParams && dateToParams && dateTypeParams) {
      dispatch(
        setFilterParams({
          ...filterParams,
          dateTo: dateToParams,
          dateFrom: dateFromParams,
        }),
      );
    }
  }, []);
  return (
    <Stack direction="horizontal" className="mb-3 justify-content-between" gap={3}>
      <Stack direction='horizontal' gap={4}>
        <h3>Draft Trip Plan</h3>
        {isOptmiseEnabled && !isOptimising && (
          <DropdownButton
            as={ButtonGroup}
            title="Assign"
            id="bg-vertical-dropdown-1"
            variant="outline-primary"
          >
            <Dropdown.Item onClick={() => handleNetworkOptimise(false)}>Evenly</Dropdown.Item>
            <Dropdown.Item onClick={() => handleNetworkOptimise(true)}>Greedy</Dropdown.Item>
          </DropdownButton>
        )}
        {isOptmiseEnabled && isOptimising && (
          <ButtonSpinner />
        )}
        <Button type="button" variant={isNotes ? 'primary' : 'light'} onClick={handleOpenNotes}>
          Notes {isNotes && <Badge bg="secondary">1</Badge>}
        </Button>
      </Stack>
      <Stack direction="horizontal" gap={4}>
        <Dropdown as={ButtonGroup}>
          <AsyncButton
            id="boards-add__btn"
            dataCy="boards-add_btn_menu"
            spinner="light"
            variant='secondary'
            title={BUTTON_TEXT}
            handleClick={handleAddTrip}
          />
          <Dropdown.Toggle split variant="secondary" id="add-trip-dropdown" data-cy="boards-add_option_btn" />
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleAddTrip} data-cy="boards-add_trip_btn">Trip</Dropdown.Item>
            <Dropdown.Item onClick={handleAddTripShipment} data-cy="boards-add__btn">Trip & Shipment</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as={ButtonGroup}>
          <SavePlanButton plan={plan} />
          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleConfirmApply}>Apply</Dropdown.Item>
            {isDispatchEnabled && (
              <Dropdown.Item onClick={handleConfirmApplyDispatch}>Dispatch</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Stack>
      {isNotesOpen && plan && (
        <DraftNotesModal plan={plan} shouldShow={isNotesOpen} onClose={() => setIsNotesOpen(false)} />
      )}
      {isConfirmAssignOpen && (
        <ConfirmModal
          title="Confirm Assign Draft"
          details="Would you like to assign the planned trips to these drivers?"
          btnTitle="Assign"
          saveDisabled={isAssigningDrivers}
          shouldShowModal={isConfirmAssignOpen}
          isSaving={isAssigningDrivers}
          handleSave={handleApply}
          cancelSave={() => setIsConfirmAssignOpen(false)}
        />
      )}
      {isConfirmAssignDispatchOpen && (
        <ConfirmModal
          title="Confirm Dispatch Draft"
          details="Would you like to dispatch the planned trips to these drivers?"
          btnTitle="Dispatch"
          saveDisabled={isAssigningDrivers}
          shouldShowModal={isConfirmAssignDispatchOpen}
          isSaving={isAssigningDrivers}
          handleSave={handleDispatch}
          cancelSave={() => setIsConfirmAssignOpen(false)}
        />
      )}
    </Stack>
  );
}

export default Header;
