/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Spinner, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import TemplatesMenu from './TemplatesMenu';

import { selectTripsByCardId, sendTripRouteAsync } from '../../../redux/slices/trips/trips';
import { GroupView, Group, NoteTemplate } from '../../../redux/models/settings.models';
import { Board, TripTableColumns } from '../../../redux/models/board.models';
import { TripBoardModel } from '../../../redux/models/trip.models';
import TripsTable from '../TripTable/TripsTable';
import { AppState } from '../../../redux/models/state.models';
// import GroupMenu from './GroupMenu';
import EditableTitle from '../components/EditableTitle';
import { getSettingByName, saveGroup } from '../../../redux/slices/settings';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { DateService } from '../../../utils/dateService';
import useAppSettings from '../../../hooks/useAppSettings';
import { formatOrgName } from '../../../utils/core.utils';
import { getTripIdsForDispatch } from '../../planner/TripPlanner/table.utils';
import { errorToast, infoToast } from '../../../components/notifications/app-toast';
import { setMultiTripDispatch } from '../../../redux/slices/notifications/notifications';
import { AsyncDispatchNotificationResponse } from '../../../redux/slices/dispatch/dispatch.models';

const getDispatchMessage = (numTrips: number) => {
  const header = `Would you like to dispatch ${numTrips} trips in this group to these drivers?`;
  const statuses = `completed, dispatched and driver confirmed`
  return `${header} Trips without drivers as well as ${statuses} trips won't be dispatched.`;
};

interface Props {
  board: Board;
  group: Group | GroupView;
  headers: TripTableColumns[];
  noteTemplates: NoteTemplate[];
  shouldExpandAllItems: boolean;
}

export default function GroupCard({
  board,
  group,
  headers,
  shouldExpandAllItems,
  noteTemplates,
}: Props) {
  const groupName = group.data.name;
  const groupId = group.entity_id;
  const dispatch = useAppDispatch();
  const { timezone } = useAppSettings();
  const { orgCode } = useParams();
  const [confirmDispatchOpen, setConfirmDispatchOpen] = useState(false);
  const [isItemExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);
  const [isCreatingTrip, setIsCreatingTrip] = useState(false);
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isAssigningDrivers, setIsAssigningDrivers] = useState(false);
  const [title, setTitle] = useState(groupName);
  const groupTrips: TripBoardModel[] = useSelector((state: AppState) => selectTripsByCardId(state, groupId));
  const templateIds = group.data.trip_template_ids || [];
  const orgName = formatOrgName(orgCode);
  const handleRename = async () => {
    if (groupName === title) {
      setIsEditingName(false);
      return;
    }
    try {
      const updates: any = {
        ...group,
        data: {
          ...group.data,
          name: title,
          board,
        },
      };
      if (updates.data.board_id) {
        delete updates.data.board_id;
      }
      const response = await saveGroup(updates);
      if (response.status < 300) {
        await dispatch(getSettingByName(SETTINGS_NAMES.GROUPS));
      } else {
        errorToast("Couldn't update board.");
      }
    } catch (error) {
      if (error instanceof Error) {
        errorToast(`Couldn't update board. ${error.message}`);
      }
    } finally {
      setIsEditingName(false);
    }
  };

  const handleDispatch = async () => {
    try {
      setIsAssigningDrivers(true);
      const tripIds = getTripIdsForDispatch(groupTrips);
      const offset = DateService.getTimezoneOffset(timezone);
      const params = {
        trip_ids: tripIds,
        user_timezone: offset,
        company_name: orgName,
      };
      const response = await sendTripRouteAsync(params);
      if (response.status === 200) {
        const queuedDispatch: AsyncDispatchNotificationResponse = {
          ...response.data.data,
          message: `${groupName} trips completed processing`
        };
        setConfirmDispatchOpen(false);
        infoToast(`${title} trips queued for dispatching.`);
        dispatch(setMultiTripDispatch(queuedDispatch));
      }
    } catch (error) {
      setIsAssigningDrivers(false);
    }
  };

  useEffect(() => {
    setIsItemExpanded(shouldExpandAllItems);
  }, [shouldExpandAllItems, headers]);
  return (
    <Accordion activeKey={isItemExpanded ? '0' : ''}>
      <Card>
        <Accordion.Item eventKey="0">
          <Card.Header
            className="group-card-header"
            onMouseEnter={() => setIsMenuShowing(true)}
            onMouseLeave={() => setIsMenuShowing(false)}
          >
            <Stack direction="horizontal" gap={1} style={{ height: '30px' }}>
              <Card.Title className="group-card-title" data-cy="board_group_title">
              <Stack direction="horizontal" gap={1}>
                <EditableTitle
                  name={title}
                  isEditing={isEditingName}
                  as='h4'
                  setName={setTitle}
                  setIsEditing={setIsEditingName}
                  onRename={handleRename}
                />
                {/* {isItemExpanded && ` (${groupTrips.length || 0})`} */}
                {/* {isItemExpanded ? groupName : `${groupName} (${groupTrips.length || 0})`} */}
              </Stack>
              </Card.Title>
              {isMenuShowing && (
                <div className="group-card-menu">
                  <TemplatesMenu
                    groupId={groupId}
                    templateIds={templateIds}
                    noteTemplates={noteTemplates}
                    setConfirmDispatchOpen={setConfirmDispatchOpen}
                    setIsCreatingTrip={setIsCreatingTrip}
                  />
                  {isCreatingTrip && <Spinner animation="border" variant="primary" size="sm" />}
                </div>
              )}
              {/* {isMenuShowing && (
                <GroupMenu onRename={handleRename} onDelete={handleDelete} />
              )} */}
            </Stack>
            <div
              data-cy={`${title}_group_collapse_btn`}
              className="custom-collapse-icon-wrapper"
              onClick={() => setIsItemExpanded(!isItemExpanded)}
            >
              <FontAwesomeIcon
                width={25}
                height={25}
                icon={faChevronDown}
                className="custom-collapse-icon"
              />
            </div>
          </Card.Header>
          <Accordion.Body style={{ background: 'white', padding: 0 }}>
            <Card.Body>
              <TripsTable headers={headers} trips={groupTrips} title={groupName} />
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
      {confirmDispatchOpen && (
        <ConfirmModal
          title={`Confirm Dispatch ${getTripIdsForDispatch(groupTrips).length} Trips`}
          details={getDispatchMessage(getTripIdsForDispatch(groupTrips).length)}
          btnTitle="Dispatch"
          saveDisabled={getTripIdsForDispatch(groupTrips).length === 0 || isAssigningDrivers}
          shouldShowModal={confirmDispatchOpen}
          isSaving={isAssigningDrivers}
          handleSave={handleDispatch}
          cancelSave={() => setConfirmDispatchOpen(false)}
        />
      )}
    </Accordion>
  );
}
