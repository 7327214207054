/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { 
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Tabs,
  Tab,
  Col,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';

import { pusher } from '../../services/pusher';

import Loader from '../../components/Loader';
import Header from './Header';
import Groups from './Groups/Groups';
import Drivers from './Drivers/Drivers';
import IntegrationsMenu from './IntegrationsMenu';
import { CollapseButton } from '../../components/shared/CollapseButton';

import {
  getColWidths,
  selectBoard,
  selectBoardSettings,
  selectGroupsByBoardId,
  selectIsSettingsLoaded,
  // updateColWidths,
} from '../../redux/slices/settings';
import {
  getTrips,
  selectTripsFilterParams,
  updateTripsFromPusher,
  removeTripFromPusher,
  selectTrips,
  setSelectedTab,
  selectDispatchTab,
  getTripListViewById,
} from '../../redux/slices/trips/trips';

import { BOARD_PAGE_TABS } from './constants/boards.constants';
import { AppState } from '../../redux/models/state.models';
import { TripListView } from '../../redux/models/trip.models';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { SHIPMENT_TABLE_HEADERS, TRIP_TABLE_HEADERS } from './TripTable/constants/tripTable.constants';
import Shipments from './Shipments/Shipments';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';
import useAuth from '../../hooks/useAuth';
import { shouldUpdateTripStore } from '../../redux/slices/trips/trips.utils';
import Analytics from '../../utils/analytics';
import { EntityContainer } from '../../redux/models/core.models';
// import { getDriverAvailability } from '../../redux/slices/driverAvailability';
// import Analytics from '../../utils/analytics';
// import { DriverAvailability } from '../../redux/models/driver.availability.models';

const { GROUPS, DRIVERS, SHIPMENTS } = BOARD_PAGE_TABS;

interface EntityNotification {
  entity_id: string;
  entity_type: string;
  event_type: string;
  author: string;
}

class Requests {
  static inFlight: EntityContainer<string> = {};
}

function Boards() {
  const { user } = useAuth();
  const { id } = useParams();
  const boardId = id || '';
  const userId = user?.id || '';
  const dispatch = useAppDispatch();
  const {
    async_dispatch: asyncDispatch,
  } = useFeatureFlags();
  const board = useSelector((state: AppState) => selectBoard(state, id));
  const groups = useSelector((state: AppState) => selectGroupsByBoardId(state, boardId));
  const selectedTab = useSelector(selectDispatchTab);
  const trips = useSelector(selectTrips);
  const isAsyncDispatchEnabled = asyncDispatch?.is_enabled || false;
  const integrations = board?.data?.integrations || [];
  const driverTags = board?.data?.driver_tags || [];
  const [shouldShowIntegrationsMenu, setShouldShowIntegrationsMenu] = useState(false);
  const [shouldExpandAllItems, setShouldExpandAllItems] = useState(true);
  const boardName = board?.data?.name;
  const groupTableHeaders = board?.data?.table_config?.group_table || TRIP_TABLE_HEADERS;
  const driverTableHeaders = board?.data?.table_config?.driver_table || TRIP_TABLE_HEADERS;
  const boardSettings = useSelector(selectBoardSettings);
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const noteTemplates = board?.data.note_templates || [];
  const savedCols = board ? getColWidths(board) : [];
  const visibleGroupHeaders = groupTableHeaders.filter((hd) => hd.visible && hd.key !== 'tripNo').map((hd) => {
    const width = savedCols.find((cl) => cl.key === hd.key)?.width || 'max-content';
    return {
      ...hd,
      width,
    };
  });
  const visibleDriverHeaders = driverTableHeaders.filter((hd) => hd.visible && hd.key !== 'tripNo').map((hd) => {
    const width = savedCols.find((cl) => cl.key === hd.key)?.width || 'max-content';
    return {
      ...hd,
      width,
    };
  });
  // const handleResize = (idx: number, width: number) => {
  //   if (!board) return;
  //   const savedCol = getColWidths(board);
  //   const savedColss = savedCol.length ? savedCol : visibleHeaders;
  //   const cols = savedColss.map((header, headerIdx) => {
  //     return {
  //       ...header,
  //       width: headerIdx === idx ? width : header.width,
  //     };
  //   });
  //   const updates: Board = {
  //     ...board,
  //     data: {
  //       ...board.data,
  //       table_config: {
  //         trip_table: cols,
  //       },
  //     },
  //   };
  //   updateColWidths(updates);
  // };

  const updateTripStore = (updatedTrip: TripListView) => {
    const shouldUpdate = shouldUpdateTripStore(updatedTrip, dateFrom, dateTo);
    // if trip matches board date, update
    if (shouldUpdate) {
      dispatch(updateTripsFromPusher({ trip: updatedTrip, boardSettings }));
    }
  };

  const fetchTripListViewById = async (notification: EntityNotification) => {
    const entityType = notification.entity_type || '';
    const tripId = notification.entity_id;
    const isInFlight = Requests.inFlight[tripId] === tripId;
    if (entityType !== 'trip' || isInFlight) return;
    try {
      Requests.inFlight[tripId] = tripId;
      const response = await getTripListViewById(tripId);
      if (response.status === 200) {
        const listViewData = response.data.data;
        updateTripStore(listViewData);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      delete Requests.inFlight[tripId];
    }
  };

  const removeTripFromStore = (deletedTrip: any) => {
    dispatch(removeTripFromPusher(deletedTrip));
  }

  useEffect(() => {
    const loadTrips = async () => {
        await dispatch(getTrips(id || '', { dateFrom, dateTo }, boardSettings));
    };
    if (isBoardSettings) loadTrips();
  }, [dispatch, id, dateFrom, dateTo, isBoardSettings, isAsyncDispatchEnabled, userId]);

  useEffect(() => {
    const boardChannel = pusher.subscribe(id || '');
    boardChannel.bind('update', (entity: TripListView) => {
      if (entity.entity_type === 'trip') updateTripStore(entity);
    });
    boardChannel.bind('notification', (notification: EntityNotification) => {
      fetchTripListViewById(notification);
    });
    boardChannel.bind('delete', (deletedTrip: any) => {
      removeTripFromStore(deletedTrip);
    });
  }, [dateFrom, dateTo, id, updateTripStore, removeTripFromStore]);

  useEffect(() => () => setShouldShowIntegrationsMenu(false), []);

  useEffect(() => {
    return () => {
      pusher.unsubscribe(id || '');
    };
  }, [id]);

  useEffect(() => {
    dispatch(resetShipments());
  }, [dispatch]);

  return boardName ? (
    <>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Row>
          <Col md={shouldShowIntegrationsMenu ? 9 : 12}>
            <Header
              board={board}
              integrations={integrations}
              shouldShowIntegrationsMenu={shouldShowIntegrationsMenu}
              setShouldShowIntegrationsMenu={setShouldShowIntegrationsMenu}
            />
            <div id="boards-groups-drivers__tab_container" className="board-tabs-wrapper">
              <Tabs activeKey={selectedTab} onSelect={(k) => dispatch(setSelectedTab(k))} className="mb-3">
                <Tab eventKey={GROUPS.eventKey} title={GROUPS.title}>
                  <Groups
                    board={board}
                    headers={visibleGroupHeaders}
                    noteTemplates={noteTemplates}
                    shouldExpandAllItems={shouldExpandAllItems}
                  />
                </Tab>
                <Tab eventKey={DRIVERS.eventKey} title={DRIVERS.title}>
                  <Drivers
                    tags={driverTags}
                    headers={visibleDriverHeaders}
                    shouldExpandAllItems={shouldExpandAllItems}
                  />
                </Tab>
                <Tab
                  eventKey={SHIPMENTS.eventKey}
                  title={SHIPMENTS.title}
                  data-cy="dispatch-board-shipments-tab"
                >
                  <Shipments
                    headers={SHIPMENT_TABLE_HEADERS}
                    shouldExpandAllItems={shouldExpandAllItems}
                  />
                </Tab>
              </Tabs>
              <CollapseButton
                shouldExpandAllItems={shouldExpandAllItems}
                setShouldExpandAllItems={setShouldExpandAllItems}
              />
            </div>
          </Col>
          {shouldShowIntegrationsMenu ? (
            <Col md={3}>
              <IntegrationsMenu
                integrations={integrations}
                groups={groups}
                trips={Object.values(trips)}
                shouldShowIntegrationsMenu={shouldShowIntegrationsMenu}
                setShouldShowIntegrationsMenu={setShouldShowIntegrationsMenu}
              />
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  ) : (
    <Loader />
  );
}

export default Boards;
