/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { 
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Tabs,
  Tab,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';

import { pusher } from '../../services/pusher';

import Loader from '../../components/Loader';
import Header from './Header';
import Groups from './Groups/Groups';
import { CollapseButton } from '../../components/shared/CollapseButton';

import {
  getColWidths,
  selectBoard,
  selectBoardSettings,
  selectIsSettingsLoaded,
} from '../../redux/slices/settings';
import {
  selectTripsFilterParams,
  updateTripsFromPusher,
  removeTripFromPusher,
  selectAreTripsLoading,
  getTripListViewById,
  getTrips,
} from '../../redux/slices/trips/trips';

import { BOARD_PAGE_TABS } from './constants/boards.constants';
import { AppState } from '../../redux/models/state.models';
import { TripListView } from '../../redux/models/trip.models';
import TripPlanner from './TripPlanner/TripPlanner';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { TRIP_TABLE_HEADERS } from './TripTable/constants/tripTable.constants';
import { loadAvailability, selectIsAvailabilityLoading } from '../../redux/slices/availability/driver.availability';
import {
  fetchDraftPlans, selectIsPlansLoading, setSelectedPlannerTab, selectPlannerTab
} from '../../redux/slices/planner/planner';
import ButtonSpinner from '../../components/shared/ButtonSpinner';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';
import useAuth from '../../hooks/useAuth';
import { shouldUpdateTripStore } from '../../redux/slices/trips/trips.utils';
import Analytics from '../../utils/analytics';
import { EntityContainer } from '../../redux/models/core.models';
import BreadCrumbs, { BreadCrumbRoute } from '../../components/shared/breadcrumbs/BreadCrumbs';
import { Board } from '../../redux/models/board.models';

const { PREVIEW, PLANNER } = BOARD_PAGE_TABS;
const getRoutes = (orgCode: string | undefined, board: Board): BreadCrumbRoute[] => {
  const routes = [
    {
      name: `${board.data.name}`,
      route: `/${orgCode || ''}/boards/${board.entity_id}`,
    },
    {
      name: 'Planner',
      route: `/${orgCode}/boards/${board.entity_id}/planner`,
    },
  ];
  return routes;
};

interface EntityNotification {
  entity_id: string;
  entity_type: string;
  event_type: string;
  author: string;
}

class Requests {
  static inFlight: EntityContainer<string> = {};
}

function Planner() {
  const { user } = useAuth();
  const { id, orgCode } = useParams();
  const userId = user?.id || '';
  const dispatch = useAppDispatch();
  const {
    trip_planner: tripPlanner,
    async_dispatch: asyncDispatch,
  } = useFeatureFlags();
  const board = useSelector((state: AppState) => selectBoard(state, id));
  const selectedTab = useSelector(selectPlannerTab);
  const isTripsLoading = useSelector(selectAreTripsLoading);
  const isPlansLoading = useSelector(selectIsPlansLoading);
  const isAvailabilityLoading = useSelector(selectIsAvailabilityLoading);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const isLoading = isTripsLoading || isPlansLoading || isAvailabilityLoading;
  const isAsyncDispatchEnabled = asyncDispatch?.is_enabled || false;
  const driverTags = board?.data?.driver_tags || [];
  const [shouldExpandAllItems, setShouldExpandAllItems] = useState(true);
  const boardName = board?.data?.name;
  const groupTableHeaders = board?.data?.table_config?.group_table || TRIP_TABLE_HEADERS;
  const boardSettings = useSelector(selectBoardSettings);
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const isTripPlannerEnabled = tripPlanner?.is_enabled || false;
  const noteTemplates = board?.data.note_templates || [];
  const savedCols = board ? getColWidths(board) : [];
  const visibleGroupHeaders = groupTableHeaders.filter((hd) => hd.visible && hd.key !== 'tripNo').map((hd) => {
    const width = savedCols.find((cl) => cl.key === hd.key)?.width || 'max-content';
    return {
      ...hd,
      width,
    };
  });
  
  const updateTripStore = (updatedTrip: TripListView) => {
    const shouldUpdate = shouldUpdateTripStore(updatedTrip, dateFrom, dateTo);
    // if trip matches board date, update
    if (shouldUpdate) {
      dispatch(updateTripsFromPusher({ trip: updatedTrip, boardSettings }));
    }
  };

  const fetchTripListViewById = async (notification: EntityNotification) => {
    const entityType = notification.entity_type || '';
    const tripId = notification.entity_id;
    const isInFlight = Requests.inFlight[tripId] === tripId;
    if (entityType !== 'trip' || isInFlight) return;
    try {
      Requests.inFlight[tripId] = tripId;
      const response = await getTripListViewById(tripId);
      if (response.status === 200) {
        const listViewData = response.data.data;
        updateTripStore(listViewData);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      delete Requests.inFlight[tripId];
    }
  };

  const removeTripFromStore = (deletedTrip: any) => {
    dispatch(removeTripFromPusher(deletedTrip));
  }

  useEffect(() => {
    const loadTrips = async () => {
        await dispatch(getTrips(id || '', { dateFrom, dateTo }, boardSettings));
    };
    if (isBoardSettings) loadTrips();
  }, [dispatch, id, dateFrom, dateTo, isBoardSettings, isAsyncDispatchEnabled, userId]);

  useEffect(() => {
    if (isTripPlannerEnabled && id) {
      // get trip planner
      dispatch(fetchDraftPlans(id, dateFrom, dateTo));
      dispatch(loadAvailability(dateFrom, dateTo));
    }
  }, [dispatch, isTripPlannerEnabled, id, dateFrom, dateTo, isAsyncDispatchEnabled, userId]);

  useEffect(() => {
    const boardChannel = pusher.subscribe(id || '');
    boardChannel.bind('update', (entity: TripListView) => {
      if (entity.entity_type === 'trip') updateTripStore(entity);
    });
    boardChannel.bind('notification', (notification: EntityNotification) => {
      fetchTripListViewById(notification);
    });
    boardChannel.bind('delete', (deletedTrip: any) => {
      removeTripFromStore(deletedTrip);
    });
  }, [dateFrom, dateTo, id, updateTripStore, removeTripFromStore]);

  useEffect(() => {
    return () => {
      pusher.unsubscribe(id || '');
    };
  }, [id]);

  useEffect(() => {
    dispatch(resetShipments());
  }, [dispatch]);

  return boardName ? (
    <>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Row>
          <BreadCrumbs routes={getRoutes(orgCode, board)} />
          <Header
            board={board}
            boardSettings={boardSettings}
          />
          <div id="boards-groups-drivers__tab_container" className="board-tabs-wrapper">
            <Tabs activeKey={selectedTab} onSelect={(k) => dispatch(setSelectedPlannerTab(k))} className="mb-3">
              <Tab eventKey={PLANNER.eventKey} title={PLANNER.title}>
                {isLoading ? (
                  <ButtonSpinner />
                ) : (
                  <TripPlanner
                    boardId={id || ''}
                    tags={driverTags}
                    boardSettings={boardSettings}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                  />
                )}
              </Tab>
              <Tab eventKey={PREVIEW.eventKey} title={PREVIEW.title}>
                <Groups
                  board={board}
                  headers={visibleGroupHeaders}
                  noteTemplates={noteTemplates}
                  shouldExpandAllItems={shouldExpandAllItems}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  tags={driverTags}
                />
              </Tab>
            </Tabs>
            <CollapseButton
              shouldExpandAllItems={shouldExpandAllItems}
              setShouldExpandAllItems={setShouldExpandAllItems}
            />
          </div>
        </Row>
      </Container>
    </>
  ) : (
    <Loader />
  );
}

export default Planner;
