/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { 
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useAppDispatch from '../../../hooks/useAppDispatch';

import Loader from '../../../components/Loader';

import {
  getColWidths,
  selectBoard,
  selectBoardSettings,
  selectDriversObject,
  selectIsSettingsLoaded,
} from '../../../redux/slices/settings';
import {
  fetchTrips,
} from '../../../redux/slices/trips/trips';

import { AppState } from '../../../redux/models/state.models';
import { TRIP_TABLE_HEADERS } from '../TripTable/constants/tripTable.constants';
import useAuth from '../../../hooks/useAuth';
import BreadCrumbs, { BreadCrumbRoute } from '../../../components/shared/breadcrumbs/BreadCrumbs';
import { Board, BoardSettings } from '../../../redux/models/board.models';
import { Driver } from '../../../redux/models/settings.models';
import BoardDateMenu from '../../boards/components/BoardDateMenu';
import { SearchDateRange } from '../../../components/modals/SearchFilter/searchFilter.models';
import { DateService } from '../../../utils/dateService';
import { TripBoardModel, TripListView } from '../../../redux/models/trip.models';
import { formatTrips } from '../TripTable/utils/tripTable.utils';
import { EntityContainer } from '../../../redux/models/core.models';
import TripsTable from '../TripTable/TripsTable';

interface DriverTrips {
  date: string;
  trips: TripBoardModel[];
}

const getDriverName = (driver: Driver | undefined) => {
  if (!driver) return '';
  return driver.data.samsara_name || driver.data.samsara_username || 'No name';
};
const getRoutes = (orgCode: string | undefined, board: Board, driver: Driver): BreadCrumbRoute[] => {
  const routes = [
    {
      name: `${board.data.name}`,
      route: `/${orgCode || ''}/boards/${board.entity_id}`,
    },
    {
      name: 'Planner',
      route: `/${orgCode}/boards/${board.entity_id}/planner`,
    },
  ];
  if (driver) {
    
    routes.push(
      {
        name: `${getDriverName(driver)}`,
        route: `/${orgCode}/boards/${board.entity_id}/planner/driver/${driver.entity_id}`,
      }
    )
  }
  return routes;
};

const formatDriverTrips = (trips: TripListView[], driverId: string, boardSettings: BoardSettings): DriverTrips[] => {
  const data = trips.filter((trip) => trip.data.driver_id === driverId);
  const driverTrips = formatTrips({ trips: data, boardSettings });
  const dates = driverTrips.reduce((store: EntityContainer<TripBoardModel[]>, trip) => {
    const tripDate = DateService.getDDMMYYYFormat(trip.startDate);
    const existing = store[tripDate] ? store[tripDate] : [];
    return {
      ...store,
      [tripDate]: [...existing, trip],
    }
  }, {});
  return Object.keys(dates)
    .map((date) => ({
      date,
      trips: dates[date] || [],
    }))
    .sort((a, b) => b.date.localeCompare(a.date))
};

function DriverTripHistory() {
  const { user } = useAuth();
  const { boardId, driverId, orgCode } = useParams();
  const userId = user?.id || '';
  const id = boardId || '';
  const dispatch = useAppDispatch();

  const board = useSelector((state: AppState) => selectBoard(state, id));
  const drivers = useSelector(selectDriversObject);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const driver = drivers[driverId || ''];
  const [isLoading, setIsLoading] = useState(false);
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [trips, setTrips] = useState<DriverTrips[]>([]);
  const today = DateService.getStartOfTheDayISO();
  const [dateRange, setDateRange] = useState<SearchDateRange>({
    dateFrom: DateService.subtractDaysFromDateISO(today, 7),
    dateTo: today,
  })
  const driverTableHeaders = board?.data?.table_config?.group_table || TRIP_TABLE_HEADERS;
  const boardSettings = useSelector(selectBoardSettings);
  const savedCols = board ? getColWidths(board) : [];
  const visibleHeaders = driverTableHeaders
    .filter((hd) => hd.visible && hd.key !== 'tripNo' && hd.key !== 'driver').map((hd) => {
      const width = savedCols.find((cl) => cl.key === hd.key)?.width || 'max-content';
      return {
        ...hd,
        width,
      };
    });
  const driverName = getDriverName(driver);
  const changeDateFilter = (selectedRange: SearchDateRange) => {
    setDateRange(selectedRange);
  };
  useEffect(() => {
    const loadTrips = async () => {
      try {
        setIsLoading(true);
        const response = await fetchTrips(id || '', dateRange);
        if (response.status === 200) {
          const tripData = response.data.data;
          setTrips([...formatDriverTrips(tripData, driverId || '', boardSettings)]);
        }
      } catch (error) {
        console.log(error); 
      } finally {
        setIsLoading(false);
      }
    };
    if (isBoardSettings) loadTrips();
  }, [dispatch, id, driverId, dateRange, isBoardSettings, userId, boardSettings]);

  return board ? (
    <>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Row>
          <BreadCrumbs routes={getRoutes(orgCode, board, driver)} />
          <BoardDateMenu
            defaultFromDate={dateRange.dateFrom}
            defaultToDate={dateRange.dateTo}
            shouldShowFilters={isDateMenuOpen}
            setShouldShowFilters={setIsDateMenuOpen}
            saveFilters={changeDateFilter}
          />
          <h3>{`${driverName} Trip History`}</h3>
          {isLoading && <Loader />}
          {!isLoading && trips.map((driverTrips) => {
            return (
              <Card>
                <Card.Header
                  className="group-card-header"
                >
                  <Card.Title as="h5">{driverTrips.date}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TripsTable
                    title={driverTrips.date}
                    trips={driverTrips.trips}
                    headers={visibleHeaders}
                    driverPlan={{}}
                  />
                </Card.Body>
              </Card>
            )
          })}
        </Row>
      </Container>
    </>
  ) : (
    <Loader />
  );
}

export default DriverTripHistory;
